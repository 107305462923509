import { IonCard, IonContent, IonHeader, IonButton, IonRow, IonGrid, IonPage, IonSearchbar, IonTitle, IonToolbar, IonCol, IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';
import { PlanningState } from '../model/Planning';
import { Transaction, TransactionState } from '../model/Transaction';
import { showMessage } from '../services/popup';
import { getTransactions } from '../services/service';

const SIZE = 50;

const PayPage: React.FC = () => {

  const [page, setPage] = useState(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const patternChanged = (pattern: string) => {
    setTransactions([]);

    setPage(0);
    loadTransactions(0, pattern);
  }

  const loadTransactions = async(p: number, pattern?: string) => {
    try {
        setTransactions(await getTransactions(p * SIZE, SIZE + 1, pattern ?? ''));
    } catch (e) {
      showMessage('Une erreur est survenue: ' + JSON.stringify(e));
    }
  }

  const stateToString = (state: PlanningState) : string => {
    switch (state) {
      case PlanningState.Done: return 'Terminée';
      case PlanningState.Null: return '';
      case PlanningState.Reserved: return 'Reservée';
      case PlanningState.Reserving: return 'En cours de reservation';
      case PlanningState.Empty: return '';
      case PlanningState.Validated: return 'Validée';
      default: return '';
    }
  }

  const hasNext = transactions.length > SIZE;
  const hasPrevious = page > 0;

  const movePage = (next: number) => {
    if (next >= 0) {
      setPage(next);
      loadTransactions(next);
    }
  }

  useEffect(() => {
    loadTransactions(0);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Paiements</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Paiements</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonGrid>
              <IonCol size='2'>
                {
                  hasPrevious ? 
                  (
                    <IonButton onClick={() => movePage(page - 1)}>
                      {'<'}
                    </IonButton>
                  ) : ''
                }
              </IonCol>
              <IonCol size='3' />
              <IonCol size='2'>
                <IonLabel>Page {page + 1}</IonLabel>
              </IonCol>
              <IonCol size='3' />
              {
                hasNext ? 
                (
                  <IonButton onClick={() => movePage(page + 1)}>
                    {'>'}
                  </IonButton>
                ) : ''
              }
              <IonCol size='2'>
                
              </IonCol>
            </IonGrid>
            <IonSearchbar debounce={1000} onIonChange={(ev) => patternChanged((ev.target as HTMLIonSearchbarElement).value!)}/>
            <IonGrid>
                <IonRow>
                <IonCol size='1'>
                    <IonLabel>Reference</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>Client</IonLabel>
                </IonCol>
                <IonCol size='1'>
                <IonLabel>Prix base -{'>'} final</IonLabel>
                </IonCol>
                <IonCol size='1'>
                <IonLabel>Date d'achat</IonLabel>
                </IonCol>
                <IonCol size='1'>
                <IonLabel>Etat</IonLabel>
                </IonCol>
                <IonCol size='1'>
                <IonLabel>Reduction</IonLabel>
                </IonCol>
                <IonCol size='1'>
                <IonLabel>10 Packs achetés</IonLabel>
                </IonCol>
                <IonCol size='5'>
                <IonLabel>Séances</IonLabel>
                </IonCol>
                </IonRow>
            {
                transactions.filter((l, i) => i < SIZE).map((l, i) => (
                <IonRow key={i}>
                <IonCol size='1'>
                    <IonLabel>{l.referenceId}</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>{l.clientName}</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>{l.price.default} -{'>'} {l.price.final}</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>{new Date(l.date).toLocaleDateString()} {new Date(l.date).toLocaleTimeString()}</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>{TransactionState[l.state].toString()}</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>{l.reductionCode}</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>{l.packBougth}</IonLabel>
                </IonCol>
                <IonCol size='5'>
                {
                    l.sessions.map((s, j) => (
                        <IonCard key={j}>
                            <IonGrid>
                                <IonRow>
                                    <IonLabel>{s.coachName}</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <IonLabel>{new Date(s.date).toLocaleDateString()} {new Date(s.date).toLocaleTimeString()}</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <IonLabel>{stateToString(s.state)}</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <IonLabel>{s.sessionPrice} € {s.byPack ? `(Pack)` : ``}</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <IonLabel>{s.refunded ? 'Remboursé le ' + (new Date(s.refundDate ?? new Date()).toLocaleDateString()) : ''}</IonLabel>
                                </IonRow>
                                { s.deleted &&
                                  <IonRow>
                                    <IonLabel>ANNULÉE</IonLabel>
                                  </IonRow>
                                }
                            </IonGrid>
                        </IonCard>
                    ))
                }
                </IonCol>
                </IonRow>
            ))
            }
            </IonGrid>
          </IonCard>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default PayPage;
