import { Location } from "./Location";
import { Speciality } from "./Speciality";

export interface PlanningSlot {
    coachId: number;
    clientId: number;
    date: Date;
    state: PlanningState;
    speciality: Speciality;
    location: Location;
    clientName: string;
}

export enum PlanningState {
    Null = 0, Empty, Reserving, Reserved, Validated, Done
}