import { PlanningState } from "./Planning";

export interface Transaction {
    referenceId: string;
    date: Date;
    clientId: number;
    clientName: string;
    reductionCode: string;
    price: Price;
    packBougth: number;
    withAI: boolean;
    state: TransactionState;
    sessions: SessionTransaction[]
}

export interface Price {
    default: number;
    final: number;
}

export enum TransactionState {
    WaitingPaiement = 0,
    // Les infos bancaires ont été validées, on attend 3j
    PaiementPending,
    // Les 3 jours sont passés et on a bien capturé l'argent
    Paid,
    PaiementError
}

export interface SessionTransaction {
    date: Date;
    state: PlanningState;
    coachId: number;
    coachName: string;
    refunded: boolean;
    sessionPrice: number;
    refundDate: Date;
    byPack: boolean;
    deleted: boolean;
}