import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { SpecialityResume } from '../model/SpecialityResume';
import { getSpecialityResume } from '../services/service';

const SpecialityTab: React.FC = () => {

  const [specialities, setSpecialities] = useState<SpecialityResume[]>([]);

  useEffect(() => {
    const getSpecialities = async () => {
      setSpecialities(await getSpecialityResume());
    };

    getSpecialities();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Cours</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Cours</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
          {
            specialities.map((s, i) => (
              <IonItem key={i}>
                <IonLabel key={i}>{s.name} - {s.reservations} séance{s.reservations >= 2 ? 's' : ''}</IonLabel>
              </IonItem>
            ))
          }
          </IonList>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default SpecialityTab;
