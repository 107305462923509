import { IonCard, IonContent, IonHeader, IonButton, IonRow, IonGrid, IonPage, IonSearchbar, IonTitle, IonToolbar, IonCol, IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Coach } from '../model/Coach';
import { showMessage } from '../services/popup';
import { getCoaches } from '../services/service';
import { CoachComponent } from '../components/CoachComponent';

const SIZE = 20;

const CoachTab: React.FC = () => {

  const [page, setPage] = useState(0);
  const [coaches, setCoaches] = useState<Coach[]>([]);

  const [current, setCurrent] = useState<Coach | undefined>(undefined);

  const patternChanged = (e: Event) => {
    setCoaches([]);

    setPage(0);
    loadCoaches(0, (e.target as HTMLIonSearchbarElement).value!);
  }

  const loadCoaches = async(p: number, pattern?: string) => {
    try {
      const c = await getCoaches(pattern ?? '', p * SIZE, SIZE + 1);
      setCoaches(c);
    } catch (e) {
      showMessage('Une erreur est survenue: ' + JSON.stringify(e));
    }
  }

  const hasNext = coaches.length > SIZE;
  const hasPrevious = page > 0;

  const movePage = (next: number) => {
    if (next >= 0) {
      setPage(next);
      loadCoaches(next);
    }
  }

  useEffect(() => {
    loadCoaches(0);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Coaches</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Coach</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonGrid>
              <IonCol size='2'>
                {
                  hasPrevious ? 
                  (
                    <IonButton onClick={() => movePage(page - 1)}>
                      {'<'}
                    </IonButton>
                  ) : ''
                }
              </IonCol>
              <IonCol size='3' />
              <IonCol size='2'>
                <IonLabel>Page {page + 1}</IonLabel>
              </IonCol>
              <IonCol size='3' />
              {
                hasNext ? 
                (
                  <IonButton onClick={() => movePage(page + 1)}>
                    {'>'}
                  </IonButton>
                ) : ''
              }
              <IonCol size='2'>
                
              </IonCol>
            </IonGrid>
          {
            current === undefined ?
            (
            <>
              <IonSearchbar debounce={1000} onIonChange={(ev) => patternChanged(ev)}/>
              <IonGrid>
              <IonRow key={0}>
                    <IonCol size='1'>
                      <IonLabel>Id</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>Nom</IonLabel>
                    </IonCol>
                    <IonCol size='1'>
                      <IonLabel>Note</IonLabel>
                    </IonCol>
                    <IonCol size='1'>
                      <IonLabel>Téléphone</IonLabel>
                    </IonCol>
                    <IonCol size='3'>
                      <IonLabel>Cours</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>Etat</IonLabel>
                    </IonCol>
                    <IonCol size='2'/>
                  </IonRow>
              {
                coaches.filter((c, i) => i < SIZE).map((c, i) => (
                  <IonRow key={i+1}>
                    <IonCol size='1'>
                      <IonLabel>{c.id}</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>{c.firstname} {c.lastname}</IonLabel>
                    </IonCol>
                    <IonCol size='1'>
                      <IonLabel>{c.note}</IonLabel>
                    </IonCol>
                    <IonCol size='1'>
                      <IonLabel>{c.phoneNumber}</IonLabel>
                    </IonCol>
                    <IonCol size='3'>
                      <IonLabel>{c.specialities.map(s => s.name).join(", ")}</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>{c.state ? `Activé` : `Désactivé`}</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonButton onClick={() => {
                        setCurrent(c)
                      }}>Voir</IonButton>
                    </IonCol>
                  </IonRow>
                ))
              }
              </IonGrid>
            </>
            )
              :
            (
              <>
                <IonButton onClick={() => setCurrent(undefined)}>
                  Retour
                </IonButton><p/>

                <CoachComponent coach={current} />
              </>
            )
          }
          </IonCard>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default CoachTab;
