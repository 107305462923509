import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';

export interface State {
  name: string | undefined;
  token: string | undefined;
}

const initialState: State = {
  name: undefined,
  token: undefined
}

export const slice = createSlice({
  name: 'slice',
  initialState,
  reducers: {
    SET_USER: (state: State, action: PayloadAction<{name: string, token: string}>) => {
        state.name = action.payload.name;
        state.token = action.payload.token;
        axios.defaults.headers.common['Supervisor'] = action.payload.token;
    },
    UNLOG_USER: (state: State) => {
        state.name = undefined;
        state.token = undefined;
        delete axios.defaults.headers.common['Supervisor'];
    }
  }
})

export const { 
    SET_USER,
    UNLOG_USER,
} = slice.actions

export default slice.reducer