import { combineReducers, createStore } from "redux";

import slice from "./slice";

const reducers = combineReducers({
    slice: slice,
});

const store = createStore(
  reducers
)

export default store;

export type RootState = ReturnType<typeof reducers> 