import { PlanningSlot, PlanningState } from "./model/Planning"

export const splitHistory = (slots: PlanningSlot[]) : {
    passed: PlanningSlot[],
    validating: PlanningSlot[],
    validated: PlanningSlot[]
} => {
    const now = new Date().getTime();

    return {
        passed: slots.filter(s => new Date(s.date).getTime() <= now),
        validated: slots.filter(s => s.state === PlanningState.Validated && new Date(s.date).getTime() > now),
        validating: slots.filter(s => s.state === PlanningState.Reserved && new Date(s.date).getTime() > now)
    }
}