export interface Reduction {
    code: string;
    value: number;
    date: Date | null;
    text: string;
    unlimited: boolean;
}

export interface AddReductionResult {
    reduction: Reduction;
    result: AddReductionType;
}

export enum AddReductionType {
    OK = 0,  CodeExist, Error, IncorrectDate
}