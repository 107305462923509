import { IonCard, IonContent, IonHeader, IonButton, IonRow, IonGrid, IonPage, IonSearchbar, IonTitle, IonToolbar, IonCol, IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';
import { ClientComponent } from '../components/ClientComponent';
import { Client, ClientPrivate } from '../model/Client';
import { showMessage } from '../services/popup';
import { getClients } from '../services/service';

const SIZE = 20;

const ClientTab: React.FC = () => {

  const [page, setPage] = useState(0);
  const [clients, setClients] = useState<ClientPrivate[]>([]);

  const [current, setCurrent] = useState<ClientPrivate | undefined>(undefined);

  const patternChanged = (e: Event) => {
    setClients([]);

    setPage(0);
    loadClients(0, (e.target as HTMLIonSearchbarElement).value!);
  }

  const loadClients = async(p: number, pattern?: string) => {
    try {
      const c = await getClients(pattern ?? '', p * SIZE, SIZE + 1);
      setClients(c);
    } catch (e) {
      showMessage('Une erreur est survenue: ' + JSON.stringify(e));
    }
  }

  const hasNext = clients.length > SIZE;
  const hasPrevious = page > 0;

  const movePage = (next: number) => {
    if (next >= 0) {
      setPage(next);
      loadClients(next);
    }
  }

  useEffect(() => {
    loadClients(0);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Clients</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Clients</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonGrid>
              <IonCol size='2'>
                {
                  hasPrevious ? 
                  (
                    <IonButton onClick={() => movePage(page - 1)}>
                      {'<'}
                    </IonButton>
                  ) : ''
                }
              </IonCol>
              <IonCol size='3' />
              <IonCol size='2'>
                <IonLabel>Page {page + 1}</IonLabel>
              </IonCol>
              <IonCol size='3' />
              {
                hasNext ? 
                (
                  <IonButton onClick={() => movePage(page + 1)}>
                    {'>'}
                  </IonButton>
                ) : ''
              }
              <IonCol size='2'>
                
              </IonCol>
            </IonGrid>
          {
            current === undefined ?
            (
            <>
              <IonSearchbar debounce={1000} onIonChange={(ev) => patternChanged(ev)}/>
              <IonGrid>
              <IonRow key={0}>
                    <IonCol size='1'>
                      <IonLabel>Id</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>Nom</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>Téléphone</IonLabel>
                    </IonCol>
                    <IonCol size='3'>
                      <IonLabel>Adresse</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>Email</IonLabel>
                    </IonCol>
                    <IonCol size='2'/>
                  </IonRow>
              {
                clients.filter((c, i) => i < SIZE).map((c, i) => (
                  <IonRow key={i+1}>
                    <IonCol size='1'>
                      <IonLabel>{c.client.id}</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>{c.client.firstname} {c.client.lastname}</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>{c.client.phoneNumber}</IonLabel>
                    </IonCol>
                    <IonCol size='3'>
                      <IonLabel>{c.address.description}</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonLabel>{c.email}</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonButton onClick={() => {
                        setCurrent(c)
                      }}>Voir</IonButton>
                    </IonCol>
                  </IonRow>
                ))
              }
              </IonGrid>
            </>
            )
              :
            (
              <>
                <IonButton onClick={() => setCurrent(undefined)}>
                  Retour
                </IonButton><p/>

                <ClientComponent client={current} />
              </>
            )
          }
          </IonCard>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default ClientTab;
