import axios from 'axios';
import { HOPLA_BASE_URL, HOPLA_URL } from '../config/config';
import { SpecialityResume } from '../model/SpecialityResume';
import { AddReductionResult, Reduction } from '../model/Reduction';
import { Coach, CoachFiles, CoachView } from '../model/Coach';
import { PlanningSlot } from '../model/Planning';
import { Client, ClientPrivate } from '../model/Client';
import { JobLog, Log } from '../model/Log';
import { Transaction } from '../model/Transaction';

export const loginAdmin = async (name: string, password: string) : Promise<{result: boolean, token: string}> => {
    return (await axios.post(HOPLA_URL + '/login', {name, password})).data;
}

export const getSpecialityResume = async () :Promise<SpecialityResume[]> => {
    return (await axios.get(HOPLA_URL + '/specialityResume')).data;
}

export const getReductions = async () :Promise<Reduction[]> => {
    return (await axios.get(HOPLA_URL + '/reductions')).data;
}

export const addReduction = async (value: number, code: string, date: Date | undefined, text: string, unlimited: boolean) :Promise<AddReductionResult> => {
    return (await axios.post(HOPLA_URL + '/addReduction', {
        value,
        code,
        date,
        text,
        unlimited
    })).data;
}

export const deleteReduction = async (code: string) :Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/deleteReduction/' + code)).data;
}

export const getCoaches = async(pattern: string, skip: number, limit: number) : Promise<Coach[]> => {
    return (await axios.post(HOPLA_URL + '/coaches', {pattern, skip, limit})).data;
}

export const getClients = async(pattern: string, skip: number, limit: number) : Promise<ClientPrivate[]> => {
    return (await axios.post(HOPLA_URL + '/clients', {pattern, skip, limit})).data;
}

export const getCoachView = async (id: number) :Promise<CoachView> => {
    return (await axios.get(HOPLA_URL + '/coach/' + id)).data;
}

export const getClientImage = async (clientId: number) : Promise<string> => {
    return (await axios.get(HOPLA_BASE_URL + '/image/client/' + clientId)).data;
}

export const getCoachImage = async (clientId: number) : Promise<string> => {
    return (await axios.get(HOPLA_BASE_URL + '/image/coach/' + clientId)).data;
}

export const getCoachHistory = async(coachId: number): Promise<PlanningSlot[]> => {
    return (await axios.get(HOPLA_URL + '/coachHistory/' + coachId)).data;
}

export const getClientHistory = async(clientId: number): Promise<PlanningSlot[]> => {
    return (await axios.get(HOPLA_URL + '/clientHistory/' + clientId)).data;
}

export const getCoachFiles = async(coachId: number): Promise<CoachFiles[]> => {
    return (await axios.get(HOPLA_URL + '/coachFiles/' + coachId)).data;
}

export const setCoachEnabled = async(coachId: number, enabled: boolean) : Promise<boolean> => {
    return (await axios.get(HOPLA_URL + '/setCoachEnabled/' + coachId + '/' + enabled)).data;
}

export const getCoachFile = async (coachId: number, name: string) => {
    return (await axios.post(HOPLA_URL + '/getFile/' + coachId, {name}, {responseType: 'blob'})).data;
}

export const getLogs = async(pattern: string, skip: number, limit: number, level: string) :Promise<Log[]> => {
    return (await axios.post(HOPLA_URL + '/log', {pattern, skip, limit, level})).data;
}

export const getJobLogs = async(pattern: string, skip: number, limit: number, success: string) :Promise<JobLog[]> => {
    return (await axios.post(HOPLA_URL + '/jobs', {pattern, skip, limit, success})).data;
}

export const getJobsNames = async() :Promise<string[]> => {
    return (await axios.get(HOPLA_URL + '/jobsNames')).data;
}

export const getLogsTemplates = async() :Promise<string[]> => {
    return (await axios.get(HOPLA_URL + '/logsTemplates')).data;
}

export const getTransactions = async(skip: number, limit: number, referenceId: string | null) :Promise<Transaction[]> => {
    return (await axios.get(HOPLA_URL + '/transactions?skip=' + skip + '&limit=' + limit + '&referenceId='+referenceId)).data;
}