import { confirmAlert } from "react-confirm-alert";

export const showMessage = (text: string) => {
  alert(text);
}

export const needConfirmation = (
    title: string,
    validate: () => void,
    refuse: () => void,
    yes?: string,
    no?: string
  ) => {
    confirmAlert({
      title: '',
      message: title,
      buttons: [
        {
          label: yes ?? 'Oui',
          onClick: () => validate()
        },
        {
          label: no ?? 'Non',
          onClick: () => refuse()
        }
      ]
    });
  };
  