import { Coach, CoachFiles, CoachView } from '../model/Coach';
import React, { useEffect, useState } from 'react';
import { IonButton, IonCard, IonCol, IonGrid, IonLabel, IonRow, IonSpinner } from '@ionic/react';
import { getCoachFile, getCoachFiles, getCoachHistory, getCoachView, setCoachEnabled } from '../services/service';
import { Image } from '../components/Image';
import { PlanningSlot } from '../model/Planning';
import { splitHistory } from '../utils';
import { PlanningSlotViewer } from './PlanningSlotViewer';
import { showMessage } from '../services/popup';

export interface CoachComponentProps {
    coach: Coach;
}

const label = {
    fontSize: '25px'
}

const label2 = {
    fontSize: '22px',
    marginTop: 10,
    marginBot: 10
}

export const CoachComponent : React.FC<CoachComponentProps> = (props) => {
    const c = props.coach;

    const [view, setView] = useState<CoachView | undefined>(undefined);
    const [slots, setSlots] = useState<PlanningSlot[]>([]);

    const [state, setState] = useState(c.state);
    const [init, setInit] = useState(false);
    const [files, setFiles] = useState<CoachFiles[]>([]);

    useEffect(() => {
        const get = async() => {
            setView(await getCoachView(c.id));
            setSlots(await getCoachHistory(c.id));
            setFiles(await getCoachFiles(c.id));
            setInit(true);
        }

        get();
    }, [c?.id ?? 0])

    const splited = splitHistory(slots);
    const changeState = async () => {
        if (!view) return;
        try {
            const r = await setCoachEnabled(c.id, !state);

            if (r) {
                setState(!state);
            }
        } catch {
            showMessage('Une erreur est survenue.');
        }
    }

    const getFile = async (file: CoachFiles) => {
        try {
            const r = await getCoachFile(c.id, file.name);
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(r);
            link.setAttribute("download", file.name + "." + file.extension);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
        <IonGrid>
            <IonRow>
                <IonCol size='4'>
                    <Image isClient={false} id={c.id}/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size='12'>
                    <IonLabel style={label}><b>Informations</b></IonLabel>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size='3'>
                <IonLabel style={label2}><b>Nom:</b> {c.lastname}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                <IonLabel style={label2}><b>Prénom</b>: {c.firstname}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                <IonLabel style={label2}><b>Note</b>: {c.note}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                <IonLabel style={label2}><b>Téléphone</b>: {c.phoneNumber}</IonLabel>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size='6'>
                <IonLabel style={label2}><b>Cours</b>: {c.specialities.map(k => k.name).join(", ")}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                <IonLabel style={label2}><b>Etat</b>: {c.state ? `Activé` : `Désactivé`}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                <IonButton
                    color={state === true ? 'danger': 'success'}
                    onClick={() => changeState()}>
                        <b>{state === true ? `Désactiver` : `Activer`}</b>
                </IonButton>
                </IonCol>
            </IonRow>
            {
                view === undefined ? (
                    <IonRow><IonSpinner/></IonRow>
                ) 
                :
                (
                        <IonRow>
                            <IonCol size='3'>
                            <IonLabel style={label2}><b>Adresse</b>: {view.address.description}</IonLabel>
                            </IonCol>
                            <IonCol size='3'>
                            <IonLabel style={label2}><b>Séances faites</b>: {view.sessionsDone}</IonLabel>
                            </IonCol>
                            <IonCol size='3'>
                            <IonLabel style={label2}><b>Email</b>: {view.email}</IonLabel>
                            </IonCol>
                            <IonCol size='3'>
                            <IonLabel style={label2}><b>Lieux de travail</b>: {view.workPlaces.map(w => w.description).join(" - ",)}</IonLabel>
                        </IonCol>
                    </IonRow>
                )
            }
        </IonGrid>
        {
            !init ? 
            (<IonSpinner/>)
            :
            (
                <>
                    <IonLabel>Fichers</IonLabel><p/>
                    <IonCard>
                    {
                        files.map((f, i) => (
                            <React.Fragment key={i}>
                                <IonLabel>
                                    <b>{f.name}</b>
                                </IonLabel>
                                <IonButton onClick={() => getFile(f)}>Télécharger</IonButton><p/>
                            </React.Fragment>
                        ))
                    }
                    </IonCard>

                    <IonLabel>Prochaines séances</IonLabel><p/>
                    {
                        splited.validated.map((s, i) => (
                            <PlanningSlotViewer key={i} date={s.date} userId={s.clientId} userName={s.clientName} state={s.state} 
                                address={s.location.description} isClient={false}/>
                        ))
                    }
                    <IonLabel>En attente de validation</IonLabel><p/>
                    {
                        splited.validating.map((s, i) => (
                            <PlanningSlotViewer key={i} date={s.date} userId={s.clientId} userName={s.clientName} state={s.state} 
                                address={s.location.description} isClient={false}/>
                        ))
                    }
                    <IonLabel>Séances passées</IonLabel><p/>
                    {
                        splited.passed.map((s, i) => (
                            <PlanningSlotViewer key={i} date={s.date} userId={s.clientId} userName={s.clientName} state={s.state} 
                                address={s.location.description} isClient={false}/>
                        ))
                    }
                    </>
            )
        }
        </>
    )
}