import { IonCard, IonContent, IonHeader, IonButton, IonRow, IonGrid, IonPage, IonSearchbar, IonTitle, IonToolbar, IonCol, IonLabel, IonChip } from '@ionic/react';
import { useEffect, useState } from 'react';
import { JobLog, Log } from '../model/Log';
import { showMessage } from '../services/popup';
import { getJobLogs, getJobsNames, getLogs } from '../services/service';

const SIZE = 50;

const JobsPage: React.FC = () => {

  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState<JobLog[]>([]);
  const [success, setSuccess] = useState<string | undefined>(undefined);

  const [jobsNames, setJobsNames] = useState<string[]>([]);

  const patternChanged = (pattern: string) => {
    setLogs([]);

    setPage(0);
    loadJobs(0, pattern);
  }

  const loadJobs = async(p: number, pattern?: string) => {
    try {
      setJobsNames(await getJobsNames());
      setLogs(await getJobLogs(pattern ?? '', p * SIZE, SIZE + 1, ''));
    } catch (e) {
      showMessage('Une erreur est survenue: ' + JSON.stringify(e));
    }
  }

  const hasNext = logs.length > SIZE;
  const hasPrevious = page > 0;

  const movePage = (next: number) => {
    if (next >= 0) {
      setPage(next);
      loadJobs(next);
    }
  }

  useEffect(() => {
    loadJobs(0);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Logs</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Logs</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonGrid>
              <IonCol size='12'>
              <div style={{display: 'flex', flexDirection: 'row', overflow: 'auto'}}>
              {
                  jobsNames.map((n, i) => (
                      <div key={i}>
                          <IonChip onClick={() => patternChanged(n)}>
                              <IonLabel>{n}</IonLabel>
                          </IonChip>
                      </div>
                  ))
              }
              </div>
              </IonCol>
              <IonCol size='2'>
                {
                  hasPrevious ? 
                  (
                    <IonButton onClick={() => movePage(page - 1)}>
                      {'<'}
                    </IonButton>
                  ) : ''
                }
              </IonCol>
              <IonCol size='3' />
              <IonCol size='2'>
                <IonLabel>Page {page + 1}</IonLabel>
              </IonCol>
              <IonCol size='3' />
              {
                hasNext ? 
                (
                  <IonButton onClick={() => movePage(page + 1)}>
                    {'>'}
                  </IonButton>
                ) : ''
              }
              <IonCol size='2'>
                
              </IonCol>
            </IonGrid>
            <IonSearchbar debounce={1000} onIonChange={(ev) => patternChanged((ev.target as HTMLIonSearchbarElement).value!)}/>
            <IonGrid>
            <IonRow key={0}>
                <IonCol size='2'>
                    <IonLabel>Job</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>Duration</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>Succès</IonLabel>
                </IonCol>
                <IonCol size='2'>
                    <IonLabel>Start</IonLabel>
                </IonCol>
                <IonCol size='3'>
                    <IonLabel>Data</IonLabel>
                </IonCol>
                <IonCol size='3'>
                    <IonLabel>Erreur</IonLabel>
                </IonCol>
                </IonRow>
              {
              logs.filter((l, i) => i < SIZE).map((l, i) => (
                  <IonRow key={i+1}>
                  <IonCol size='2'>
                      <IonLabel>{l.jobName}</IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                      <IonLabel>{l.duration}</IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                      <IonLabel>{l.success ? `Oui` : `Non`}</IonLabel>
                  </IonCol>
                  <IonCol size='2'>
                      <IonLabel>{new Date(l.start).toLocaleDateString()} {new Date(l.start).toLocaleTimeString()}</IonLabel>
                  </IonCol>
                  <IonCol size='3'>
                      <IonLabel>{l.data}</IonLabel>
                  </IonCol>
                  <IonCol size='3'>
                      <IonLabel>{l.error}</IonLabel>
                  </IonCol>
                  </IonRow>
              ))
              }
            </IonGrid>
          </IonCard>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default JobsPage;
