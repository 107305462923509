import React, { useEffect, useState } from 'react';
import { getCoachImage, getClientImage } from '../services/service';

export interface ImageProps {
    isClient: boolean;
    id: number;
}

export const Image :React.FC<ImageProps> = (props) => {
    const [image, setImage] = useState<string>("");
    const [init, setInit] = useState(false);

    useEffect(() => {
        const load = async () => {
            const placeholderImg = "/default-avatar-profile.jpeg";
            const clientImg = props.isClient ? await getClientImage(props.id) : await getCoachImage(props.id);
            if (clientImg) {
                setImage(clientImg);
            } else {
                setImage(placeholderImg);
            }
        };
        
        if (!init) {
            load().catch(console.error);
            setInit(true);
        }
    }, [init]);

    return (
        <div
        style={{
            width: "200px",
            height: "200px",
            padding: 0,
            borderRadius: "100%",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
        }}/>
    );
}