import { Coach, CoachView } from '../model/Coach';
import React, { useEffect, useState } from 'react';
import { IonCard, IonCol, IonGrid, IonLabel, IonRow, IonSpinner } from '@ionic/react';
import { getClientHistory, getCoachHistory, getCoachView } from '../services/service';
import { Image } from '../components/Image';
import { PlanningSlot } from '../model/Planning';
import { splitHistory } from '../utils';
import { PlanningSlotViewer } from './PlanningSlotViewer';
import { ClientPrivate } from '../model/Client';

export interface ClientProps {
    client: ClientPrivate;
}

const label = {
    fontSize: '25px'
}

const label2 = {
    fontSize: '22px',
    marginTop: 10,
    marginBot: 10
}

export const ClientComponent : React.FC<ClientProps> = (props) => {
    const c = props.client;

    const [slots, setSlots] = useState<PlanningSlot[]>([]);
    const [init, setInit] = useState(false);

    useEffect(() => {
        const get = async() => {
            setSlots(await getClientHistory(c.client.id));
            setInit(true);
        }

        get();
    }, [c?.client?.id ?? 0])

    const splited = splitHistory(slots);

    return (
        <>
        <IonGrid>
            <IonRow>
                <IonCol size='4'>
                    <Image isClient={true} id={c.client.id}/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size='12'>
                    <IonLabel style={label}><b>Informations</b></IonLabel>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonLabel style={label2}><b>Nom:</b> {c.client.lastname}</IonLabel>
            </IonRow>
            <IonRow>
                <IonLabel style={label2}><b>Prénom</b>: {c.client.firstname}</IonLabel>
            </IonRow>
            <IonRow>
                <IonLabel style={label2}><b>Téléphone</b>: {c.client.phoneNumber}</IonLabel>
            </IonRow>
            <IonRow>
                <IonLabel style={label2}><b>Email</b>: {c.email}</IonLabel>
            </IonRow>
        </IonGrid>
        {
            !init ? 
            (<IonSpinner/>)
            :
            (
                <>
                    <IonLabel>Prochaines séances</IonLabel><p/>
                    {
                        splited.validated.map((s, i) => (
                            <PlanningSlotViewer key={i} date={s.date} userId={s.clientId} userName={s.clientName} state={s.state} 
                                address={s.location.description} isClient={true}/>
                        ))
                    }
                    <IonLabel>En attente de validation</IonLabel><p/>
                    {
                        splited.validating.map((s, i) => (
                            <PlanningSlotViewer key={i} date={s.date} userId={s.clientId} userName={s.clientName} state={s.state} 
                                address={s.location.description} isClient={true}/>
                        ))
                    }
                    <IonLabel>Séances passées</IonLabel><p/>
                    {
                        splited.passed.map((s, i) => (
                            <PlanningSlotViewer key={i} date={s.date} userId={s.clientId} userName={s.clientName} state={s.state} 
                                address={s.location.description} isClient={true}/>
                        ))
                    }
                </>
            )
        }
        </>
    )
}