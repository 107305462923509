import { IonCard, IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import { PlanningState } from "../model/Planning";
import { Image } from './Image';

export interface SlotProps {
    date: Date;
    userName: string;
    userId: number;
    isClient: boolean;
    state: PlanningState;
    address: string;
}

export const PlanningSlotViewer : React.FC<SlotProps> = (props) => {
    return (
        <IonCard style={{width: '70%'}}>
        <IonGrid>
            <IonRow>
                <IonCol size='4'>
                    <Image id={props.userId} isClient={!props.isClient} />
                </IonCol>
                <IonCol size='4'>
                    <IonLabel style={{fontSize: '20px'}}><b>{props.isClient ? `Coach:` : `Client:`}</b> {props.userName}</IonLabel><p/>
                    <IonLabel style={{fontSize: '20px'}}><b>Date</b>: {new Date(props.date).toLocaleDateString()} {new Date(props.date).toLocaleTimeString()}</IonLabel>
                </IonCol>
                <IonCol size='4'>
                    <IonLabel style={{fontSize: '20px'}}><b>Lieu</b>: {props.address}</IonLabel><p/>
                    <IonLabel style={{fontSize: '20px'}}><b>Etat</b>: {PlanningState[props.state].toString()}</IonLabel>
                </IonCol>
            </IonRow>
        </IonGrid>
        </IonCard>
    );
}