import { IonCard, IonContent, IonHeader, IonButton, IonRow, IonGrid, IonPage, IonSearchbar, IonTitle, IonToolbar, IonCol, IonLabel, IonChip, IonSelect, IonSelectOption } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Log } from '../model/Log';
import { showMessage } from '../services/popup';
import { getLogs, getLogsTemplates } from '../services/service';

const SIZE = 50;

const LogPage: React.FC = () => {

  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState<Log[]>([]);
  const [level, setLevel] = useState('');

  const [templates, setTemplates] = useState<string[]>([]);

  const patternChanged = (pattern: string) => {
    setLogs([]);

    setPage(0);
    loadLogs(0, pattern);
  }

  const loadLogs = async(p: number, pattern?: string, level?: string) => {
    try {
      setTemplates(await getLogsTemplates());
      setLogs(await getLogs(pattern ?? '', p * SIZE, SIZE + 1, level ?? ''));
    } catch (e) {
      showMessage('Une erreur est survenue: ' + JSON.stringify(e));
    }
  }

  const onSelectLevel = (l: any) => {
    setLevel(l.detail.value!);
    setPage(0);
    loadLogs(0, '', l.detail.value!);
  }

  const hasNext = logs.length > SIZE;
  const hasPrevious = page > 0;

  const movePage = (next: number) => {
    if (next >= 0) {
      setPage(next);
      loadLogs(next);
    }
  }

  useEffect(() => {
    loadLogs(0);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Logs</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Logs</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonGrid>
              <IonCol size='3'>
                <IonLabel>Age</IonLabel>
                <IonSelect placeholder="Level" onIonChange={onSelectLevel}>
                  <IonSelectOption value="Warning">Warning</IonSelectOption>
                  <IonSelectOption value="Information">Information</IonSelectOption>
                  <IonSelectOption value="Error">Error</IonSelectOption>
                </IonSelect>
              </IonCol>
              <IonCol size='9'>
              <div style={{display: 'flex', flexDirection: 'row', overflow: 'auto'}}>
              {
                  templates.map((n, i) => (
                      <div key={i}>
                          <IonChip onClick={() => patternChanged(n)}>
                              <IonLabel>{n}</IonLabel>
                          </IonChip>
                      </div>
                  ))
              }
              </div>
              </IonCol>
              <IonCol size='2'>
                {
                  hasPrevious ? 
                  (
                    <IonButton onClick={() => movePage(page - 1)}>
                      {'<'}
                    </IonButton>
                  ) : ''
                }
              </IonCol>
              <IonCol size='3' />
              <IonCol size='2'>
                <IonLabel>Page {page + 1}</IonLabel>
              </IonCol>
              <IonCol size='3' />
              {
                hasNext ? 
                (
                  <IonButton onClick={() => movePage(page + 1)}>
                    {'>'}
                  </IonButton>
                ) : ''
              }
              <IonCol size='2'>
                
              </IonCol>
            </IonGrid>
            <IonSearchbar debounce={1000} onIonChange={(ev) => patternChanged((ev.target as HTMLIonSearchbarElement).value!)}/>
            <IonGrid>
            <IonRow key={0}>
                <IonCol size='1'>
                    <IonLabel>Id</IonLabel>
                </IonCol>
                <IonCol size='3'>
                    <IonLabel>Template</IonLabel>
                </IonCol>
                <IonCol size='3'>
                    <IonLabel>Message</IonLabel>
                </IonCol>
                <IonCol size='2'>
                    <IonLabel>Date</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>Level</IonLabel>
                </IonCol>
                <IonCol size='2'>
                    <IonLabel>Erreur</IonLabel>
                </IonCol>
                </IonRow>
            {
            logs.filter((l, i) => i < SIZE).map((l, i) => (
                <IonRow key={i+1}>
                <IonCol size='1'>
                    <IonLabel>{l.id}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                    <IonLabel>{l.messageTemplate}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                    <IonLabel>{l.message}</IonLabel>
                </IonCol>
                <IonCol size='2'>
                    <IonLabel>{new Date(l.timeStamp).toLocaleDateString()} {new Date(l.timeStamp).toLocaleTimeString()}</IonLabel>
                </IonCol>
                <IonCol size='1'>
                    <IonLabel>{l.level}</IonLabel>
                </IonCol>
                <IonCol size='2'>
                    <IonLabel>{l.exception}</IonLabel>
                </IonCol>
                </IonRow>
            ))
            }
            </IonGrid>
          </IonCard>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default LogPage;
