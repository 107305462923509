import { IonButton, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonRoute, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { AddReductionType, Reduction } from '../model/Reduction';
import { needConfirmation, showMessage } from '../services/popup';
import { addReduction, deleteReduction, getReductions } from '../services/service';

const ReductionTab: React.FC = () => {

  const [reductions, setReductions] = useState<Reduction[]>([]);
  const [update, needUpdate] = useState(0);

  const [promotionCode, setPromotionCode] = useState('');
  const [reductionCode, setReductionCode] = useState('');
  const [promotionText, setPromotionText] = useState('');
  const [reductionDays, setReductionDays] = useState('');
  const [reductionUnlimited, setReductionUnlimited] = useState(false);

  const [promotionValue, setPromotionValue] = useState('');
  const [reductionValue, setReductionValue] = useState('');

  const goUpdate = () => needUpdate((update + 1) % 1000);

  const getReductionValue = (value: number) : number => {
    return parseFloat((value).toPrecision(2)) * 100;
  }

  const removeReduction = async(id: string) => {
    const r = await deleteReduction(id);
    goUpdate();
  }

  const addPromotion = async (prom: boolean) => {
    let date = undefined;
    
    if (!prom && !reductionUnlimited) {
      const r = parseInt(reductionDays);

      if (r <= 0) {
        showMessage(`'${reductionDays}' doit être un nombre de jour, ou bien Illimité doit être coché.`);
        return;
      }

      date = new Date();
      date.setDate(date.getDate() + r);
    }

    const text = prom ? promotionText : '';
    const code = prom ? promotionCode : reductionCode;
    const value = prom ? promotionValue : reductionValue;

    if (prom && (text?.length ?? 0) == 0) {
      showMessage('La promotion doit avoir un texte.');
      return;
    }

    if ((code?.length ?? 0) == 0) {
      showMessage('La promotion doit avoir un texte.');
      return;
    }

    const v = parseInt(value);

    if (v <= 0 || v > 100) {
      showMessage('La valeur de reduction est incorrecte: elle doit être entre 1 et 100');
      return;
    }

    try {
      const prec = parseFloat((v / 100).toPrecision(2));
      const result = await addReduction(prec, code, date, text, reductionUnlimited);

      if (result.result === AddReductionType.OK) {
        goUpdate();
      } else {
        showMessage('Impossible d\'ajouter cette reduction: ' + AddReductionType[result.result].toString());
      }
    } catch (e)
    {
      showMessage('Une erreur est survenue.');
    }
  }

  useEffect(() => {
    const get = async () => {
      setReductions(await getReductions());
    };

    get();
  }, [update]);

  const promotions = reductions.filter(s => s.date == null);
  const promotion = promotions.length > 0 ? promotions[0] : null;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Reductions / Promotions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Reductions</IonTitle>
          </IonToolbar>
        </IonHeader>
          <IonCard>
            <IonLabel>Promotion</IonLabel><p/>
            {
              promotion === null ? (
                <IonGrid>
                  <IonRow>
                    <IonCol size='2'>
                      <IonLabel>Ajouter une promotion</IonLabel>
                    </IonCol>
                    <IonCol size='2'>
                      <IonInput
                        placeholder='Code de la promotion'
                        value={promotionCode}
                        onIonChange={e => setPromotionCode(e.detail.value!)}
                        />
                    </IonCol>
                    <IonCol size='2'>
                      <IonInput
                        placeholder='Texte'
                        type='text'
                        value={promotionText}
                        onIonChange={e => setPromotionText(e.detail.value!)}
                        />
                    </IonCol>
                    <IonCol size='2'>
                      <IonInput
                        type='number'
                        placeholder='Pourcentage de reduction'
                        value={promotionValue}
                        onIonChange={e => setPromotionValue(e.detail.value!)}
                        />
                    </IonCol>
                    <IonCol size='2'>
                      <IonButton onClick={() => addPromotion(true)}>
                        Ajouter
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              ) :
              (
                <IonRow>
                <IonCol size='3'>
                  <IonLabel>Code: {promotion.code}</IonLabel>
                </IonCol>
                <IonCol size='6'>
                  <IonLabel>{promotion.text}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                  <IonButton onClick={() => removeReduction(promotion.code)}>
                    Supprimer
                  </IonButton>
                </IonCol>
                </IonRow>
              )
            }
          </IonCard>
          <IonCard>
          <IonGrid>
            <IonRow><IonLabel>Ajouter une reduction</IonLabel></IonRow>
            <IonRow style={{height: '100px'}}>
              <IonCol size='2'>
                <IonInput
                  placeholder='Code de la reduction'
                  value={reductionCode}
                  onIonChange={e => setReductionCode(e.detail.value!)}
                  />
              </IonCol>
              <IonCol size='2'>
                <IonInput
                  placeholder='Jours de validité'
                  type='number'
                  value={reductionDays}
                  onIonChange={e => setReductionDays(e.detail.value!)}
                  />
              </IonCol>
              <IonCol size='2'>
                <IonInput
                  placeholder='Pourcentage de reduction'
                  value={reductionValue}
                  onIonChange={e => setReductionValue(e.detail.value!)}
                  />
              </IonCol>
              <IonLabel style={{marginTop: '5px', marginRight: '5px'}}>Illimité</IonLabel>
              <IonCol size='2'>
                <IonCheckbox
                  placeholder='Illimité'
                  value={reductionUnlimited}
                  onIonChange={e => setReductionUnlimited(e.detail.checked!)}
                  />
              </IonCol>
              <IonCol size='2'>
                <IonButton onClick={() => addPromotion(false)}>
                  Ajouter
                </IonButton>
              </IonCol>
            </IonRow>
          {
            reductions.filter(s => s.date || s.unlimited).map((s, i) => (
              <IonRow>
                <IonCol size='3'>
                  <IonLabel key={i}>Code: {s.code}</IonLabel>
                </IonCol>
                <IonCol size='3'>
                  { s.unlimited ? <IonLabel key={i} style={{fontSize: '2em', marginBottom: '5px'}}>{s.unlimited ? '∞' : ''}</IonLabel>
                  :
                  <IonLabel key={i}>Expire le {s.date ? new Date(s.date).toLocaleDateString() : ''}</IonLabel> }
                </IonCol>
                <IonCol size='3'>
                  <IonLabel key={i}>Reduction de {getReductionValue(s.value)} %</IonLabel>
                </IonCol>
                <IonCol size='3'>
                  <IonButton key={i} onClick={() => removeReduction(s.code)}>
                    Supprimer
                  </IonButton>
                </IonCol>
              </IonRow>
            ))
          }
          </IonGrid>
          </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ReductionTab;
