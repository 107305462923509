import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle, person, man, navigate, grid, logoUsd, logoEuro} from 'ionicons/icons';
import SpecialityTab from './pages/SpecialityTab';
import ReductionTab from './pages/ReductionTab';
import { LoginPage } from './pages/LoginPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import CoachTab from './pages/CoachTab';
import ClientTab from './pages/ClientTab';
import LogPage from './pages/LogPage';
import JobsPage from './pages/JobsPage';
import { ChartsPage } from './pages/ChartsPage';
import PayPage from './pages/PayPage';

setupIonicReact();

const App: React.FC = () => {
  const logged = useSelector((s: RootState) => s.slice.name !== undefined);

  return (
    <IonApp>
      {
        logged ?
      (
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/speciality">
                <SpecialityTab />
              </Route>
              <Route exact path="/reductions">
                <ReductionTab />
              </Route>
              <Route exact path="/">
                <Redirect to="/speciality" />
              </Route>
              <Route exact path='/coach'>
                <CoachTab />
              </Route>
              <Route exact path='/client'>
                <ClientTab />
              </Route>
              <Route exact path='/logs'>
                <LogPage />
              </Route>
              <Route exact path='/jobs'>
                <JobsPage />
              </Route>
              {/* <Route exact path='/charts'>
                <ChartsPage />
              </Route> */}
              <Route exact path='/pay'>
                <PayPage />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="speciality" href="/speciality">
                <IonIcon icon={triangle} />
                <IonLabel>Cours</IonLabel>
              </IonTabButton>
              <IonTabButton tab="reductions" href="/reductions">
                <IonIcon icon={navigate} />
                <IonLabel>Reductions</IonLabel>
              </IonTabButton>
              <IonTabButton tab="coach" href="/coach">
                <IonIcon icon={man} />
                <IonLabel>Coach</IonLabel>
              </IonTabButton>
              <IonTabButton tab="client" href="/client">
                <IonIcon icon={person} />
                <IonLabel>Client</IonLabel>
              </IonTabButton>
              <IonTabButton tab="jobs" href="/jobs">
                <IonIcon icon={man} />
                <IonLabel>Jobs</IonLabel>
              </IonTabButton>
              <IonTabButton tab="logs" href="/logs">
                <IonIcon icon={person} />
                <IonLabel>Logs</IonLabel>
              </IonTabButton>
              {/* <IonTabButton tab="charts" href="/charts">
                <IonIcon icon={grid} />
                <IonLabel>Charts</IonLabel>
              </IonTabButton> */}
              <IonTabButton tab="pay" href="/pay">
                <IonIcon icon={logoEuro} />
                <IonLabel>Paiments</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      ) : (
        <LoginPage />
      )
    }
    </IonApp>
  );
}

export default App;
