import { IonCard, IonContent, IonInput, IonLabel, IonButton } from "@ionic/react"
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loginAdmin } from "../services/service";
import { SET_USER } from "../store/slice";

const inputStyle = {
    border: '1px solid black',
    margin: '5px 15px',
}

export const LoginPage : React.FC = () => {

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const login = async() => {
        try {
            const r = await loginAdmin(name, password);

            if (r.result) {
                dispatch(SET_USER({name, token: r.token}));
                setError('');
            } else {
                setError('Mauvais login ou mot de passe.');
            }
        } catch (e)
        {
            setError('Une erreur est survenue.');
        }
    }

    return (
        <IonContent>
            <IonCard style={{flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
                <IonLabel>Login</IonLabel>
                <IonInput onIonChange={e => setName(e.detail.value!)}
                    placeholder='Name' style={inputStyle}/>
                <IonInput onIonChange={e => setPassword(e.detail.value!)}
                    placeholder='Mot de passe'
                    type='password' style={inputStyle}/>
                <IonButton onClick={() => login()}>
                    <IonLabel>Connexion</IonLabel>
                </IonButton>
                <p/>
                <IonLabel>{error}</IonLabel>
            </IonCard>
        </IonContent>
    )
}